<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>접촉 이력조회</v-card-title>
        <v-card-text>내용</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AccidentHistory',
  metaInfo: {
    title: '접촉이력 조회'
  },
  created() {
    this.$store.commit('setAppBarTitle', '접촉이력 조회')
  }
}
</script>
